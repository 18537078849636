import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { defineElement } from "@lordicon/element";
import lottie from "lottie-web";
import { PolicyComponent } from "./policy/policy.component";
import {
  NgbAccordionBody,
  NgbAccordionButton, NgbAccordionCollapse,
  NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem,
  NgbDropdown,
  NgbDropdownToggle,
  NgbHighlight, NgbNav, NgbNavContent,
  NgbNavItem, NgbNavLink,
  NgbNavOutlet,
  NgbPagination
} from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { PolicyModule } from "./policy/policy.module";
import { NgSelectComponent } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    PolicyComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    NgbNavOutlet,
    NgbNavItem,
    NgbPagination,
    NgbDropdown,
    NgbDropdownToggle,
    NgbHighlight,
    NgbNavContent,
    NgbNav,
    NgbNavLink,
    FormsModule,
    SharedModule,
    PolicyModule,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionBody,
    NgSelectComponent,
    ReactiveFormsModule
  ],
  providers: [
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
